.contact{
  margin-top: 20rem;
}

@media screen and (max-width: 1050px) {

    .contact{
        width: 66rem;
        padding-right: 3rem;
      }
      
    }
    
    @media screen and (max-width: 650px) {
      .contact{
        width: 66rem;
        padding-right: 3rem;
      }
      
    }
    
    @media screen and (max-width: 490px) {
    .contact{
        width: 66rem;
        padding-right: 3rem;
      }
    
    }
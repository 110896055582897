*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: rgb(200,200,200);
}


.App {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1050px) {

  .App{
    padding: 0;
    padding-top: 1rem;
    margin: 0;
    width: 66rem;
  }


  
}

@media screen and (max-width: 650px) {
  .App{
    padding: 0;
    padding-top: 1rem;
    margin: 0;
    width: 66rem;
  }

  
}

@media screen and (max-width: 490px) {
  .App{
    padding: 0;
    padding-top: 1rem;
    margin: 0;
    width: 66rem;
  }

}


.nav{
    display: flex;
    padding-left: 2rem;
    width: 100%;
    height: 4rem;
    flex-direction: row;
    background-color: #fff;
    z-index: 1;
    opacity: 0.7;
    border-radius: 1rem;
    box-shadow: 1px 2px 3px 2px rgb(112, 110, 110);
}

.logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3rem;
    cursor: pointer;
}


.link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    cursor: pointer;
}

.link p{
    padding-left: 4rem;
    cursor: pointer;

}

.link p a{
    text-decoration: none;
    padding: 0.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 1rem;
    color: #000;
}

.link p a:hover{
    background-color: rgb(200, 200, 200);
}

@media screen and (max-width: 1050px) {

    .nav{
        width: 66rem;
      }

    
  }
  
  @media screen and (max-width: 650px) {
    .nav{
        width: 66rem;
      }
    
  }
  
  @media screen and (max-width: 490px) {
    .nav{
        width: 66rem;
      }
  
  }


.home{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
}

.home_img{
  position: fixed;
  z-index: -1;
  min-height: 100rem;

}


.helojex{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 14rem;
    z-index: 1;
    padding: 2rem;
    margin-bottom: 5rem;
    flex-direction: column;
    backdrop-filter: blur(3rem);
}

.helojex h1{
    font-size: 3rem;
}

.helojex h2{
    font-size: 2rem;
    padding: 0.5rem;
}

@media screen and (max-width: 1050px) {

  .home{
    width: 66rem;
    padding-right: 1rem;
    top: 0;
    left: 0;
  }
  .helojex{
    margin-top: 28rem;
  }
  .home_img{
    position: fixed;
    top: 0;
    left: 0;
    width: 66rem;
    z-index: -1;
  }
  }
    
  
  @media screen and (max-width: 650px) {
    .home{
      width: 66rem;
      padding-right: 1rem;
      top: 0;
      left: 0;
    }
    .helojex{
      margin-top: 28rem;
    }
    .home_img{
      position: fixed;
      top: 0;
      left: 0;
      width: 66rem;
      z-index: -1;
    }
    
  }
  
  @media screen and (max-width: 490px) {
    .home{
      width: 66rem;
      padding-right: 1rem;
      top: 0;
      left: 0;
    }
    .helojex{
      margin-top: 28rem;
    }
    .home_img{
      position: fixed;
      top: 0;
      left: 0;
      width: 66rem;
      z-index: -1;
    }
  
  }
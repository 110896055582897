
.content{
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.about{
    display: flex;
    flex-direction: row;
}

.vision{
    display: flex;
    flex-direction: row;
}

.hisse{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1050px) {

    .content{
        width: 66rem;
        padding-right: 3rem;
      }
      
    }
    
    @media screen and (max-width: 650px) {
        .content{
            width: 66rem;
            padding-right: 3rem;
          }
      
    }
    
    @media screen and (max-width: 490px) {
      .content{
        margin-top: 28rem;
        width: 66rem;
        padding-right: 3rem;
      }
    
    }
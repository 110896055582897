
.up_button{
    width: 3.5rem;
    height: 3.5rem;
    border: solid 2px;
    border-radius: 50%;
    margin-left: 28rem;
}

.footer{
    z-index: 1;
    display: flex;
}

.text_footer{
    padding: 0.5rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding-left: 34rem;
}



@media screen and (max-width: 1050px) {

    .footer{
        width: 66rem;
        padding-right: 3rem;
        
      }
      .footer_mdb{
        width: 66rem;

    }
      
    }
    
    @media screen and (max-width: 650px) {
        .footer{
            width: 66rem;
            padding-right: 3rem;
          }
          
          .footer_mdb{
            width: 66rem;
        }
    
        .text_footer{
            width: 66rem;
            padding-left: 25rem;
        }
    
        .up_button{
            margin-left: 20rem;
        }
      
    }
    
    @media screen and (max-width: 490px) {
      .footer{
        width: 66rem;
        padding-right: 3rem;
      }
      
      .footer_mdb{
        width: 66rem;
    }

    .text_footer{
        width: 66rem;
        padding-left: 25rem;
    }

    .up_button{
        margin-left: 20rem;
    }
    
    }